import { Theme } from '@material-ui/core/styles';
import Color from 'color';
import WebFont from 'webfontloader';

import { getTextColorForBackground, mixColors } from 'utils/general';

import { partition } from '../utils/standard';

import {
  ACTION_COLOR_OUTLINE_OPACITY,
  BACKGROUND_COLOR_DEEPER_OFFSET,
  BACKGROUND_COLOR_OFFSET,
  DEFAULT_GLOBAL_STYLE_CONFIG,
  DROP_SHADOW_OPACITY,
  INTERACTION_STATE_OPACITY,
  PLACEHOLDER_TEXT_OPACITY,
  TEXT_SIZE_OFFSET_MAP,
} from './constants';
import {
  CATEGORICAL_HUE_KEYS,
  GlobalFontConfig,
  GlobalStyleConfig,
  GlobalStyleTextConfig,
  OPTIONAL_CATEGORICAL_HUE_KEYS,
} from './types';

// In case selected font is a custom font, removing extension
export const getFontFamilyName = (font: string): string => font.split('.')[0];

export const getBaseGlobalStyles = (config: GlobalStyleConfig | null): GlobalStyleConfig =>
  config ?? DEFAULT_GLOBAL_STYLE_CONFIG;

// Gets the categorical colors that have defined values in the global styles config
export function getCategoricalColors(globalStyleConfig: GlobalStyleConfig) {
  const colors: string[] = [];
  const colorPalette = globalStyleConfig.visualizations.categoricalPalette;
  CATEGORICAL_HUE_KEYS.concat(OPTIONAL_CATEGORICAL_HUE_KEYS).forEach((hue) => {
    const color = colorPalette?.[hue];
    if (color) colors.push(color);
  });

  return colors;
}

export function getDivergingColors(globalStyleConfig: GlobalStyleConfig) {
  const divergingColors = globalStyleConfig.visualizations.divergingPalette;
  const { hue1, hue2, hue3 } = divergingColors;

  return [
    hue1,
    mixColors(hue1, hue2, 0.66).hex().toString(),
    mixColors(hue1, hue2, 0.33).hex().toString(),
    hue2,
    mixColors(hue2, hue3, 0.66).hex().toString(),
    mixColors(hue2, hue3, 0.33).hex().toString(),
    hue3,
  ];
}

export function getGradientColors(globalStyleConfig: GlobalStyleConfig) {
  const gradientColors = globalStyleConfig.visualizations.gradientPalette;
  const { hue1, hue2 } = gradientColors;

  return [
    hue1,
    mixColors(hue1, hue2, 0.75).hex().toString(),
    mixColors(hue1, hue2, 0.5).hex().toString(),
    mixColors(hue1, hue2, 0.25).hex().toString(),
    hue2,
  ];
}

const fontExtensionToFormatMap: Partial<Record<string, string>> = {
  otc: 'collection',
  ttc: 'collection',
  eot: 'embedded-opentype',
  otf: 'opentype',
  ttf: 'truetype',
  svg: 'svg',
  svgz: 'svg',
  woff: 'woff',
  woff2: 'woff2',
};

function getFontFormat(fontExtension: string) {
  return fontExtensionToFormatMap[fontExtension.toLowerCase()];
}

function createFontFaceCSS(
  font: string,
  folder: string,
  teamId: number,
  weight?: number,
  familyId?: string,
) {
  // Backend validates font names so all of these should be defined
  const [fontId, fontExt] = font.split('.');
  const formatType = getFontFormat(fontExt);

  const fontUrl = `https://explo-custom-fonts.s3.us-west-1.amazonaws.com/${folder}/${teamId}/${font}`;
  return `
      @font-face {
        font-family: "${familyId || fontId}";
        src: url("${fontUrl}") ${formatType ? `format("${formatType}")` : ''};
        ${weight ? `font-weight: ${weight};` : ''}
      }
      `;
}

function createFontWeightMap(googleFonts: { font?: string; weight?: number }[]) {
  const fontWeights: Map<string, Set<number>> = new Map();
  googleFonts.forEach((fontConfig) => {
    const font = fontConfig?.font;
    if (!font) return;
    const weights = fontWeights.get(font) || new Set();
    const weight = fontConfig?.weight;
    if (weight) weights.add(weight);
    fontWeights.set(font, weights);
  });
  return fontWeights;
}

export function loadFonts(
  text: GlobalStyleTextConfig,
  fontConfig: GlobalFontConfig,
  teamId: number,
  isStylesPage = false,
) {
  if (!text) return;

  const primaryFont = text.primaryFont;
  const secondaryFont = text.secondaryFont;
  const fonts = [
    { font: primaryFont, weight: text.primaryWeight },
    { font: secondaryFont, weight: text.secondaryWeight },
    ...Object.values(text.overrides).flatMap((override) => {
      if (!override) return [];
      if (override.font) return [{ font: override.font, weight: override.weight }];
      if (!override.weight) return [];
      return [
        ...(primaryFont ? [{ font: primaryFont, weight: override.weight }] : []),
        ...(secondaryFont ? [{ font: secondaryFont, weight: override.weight }] : []),
      ];
    }),
  ];

  const familySet = new Set(fontConfig.map((f) => f.id));
  const [orphanFonts, otherFonts] = partition(fonts, (f) => f.font?.includes('.'));
  const [fontFamilies, googleFonts] = partition(otherFonts, (f) => f.font && familySet.has(f.font));

  // Loading Google Fonts
  const fontWeights = createFontWeightMap(googleFonts);
  const families = new Set<string>();
  for (const [font, weights] of fontWeights.entries()) {
    weights.add(400); // Always load the regular weight (400)
    weights.add(700); // Always load the bold weight (700)
    const weightStr = weights.size ? `:${Array.from(weights.values()).join(',')}` : '';
    families.add(font + weightStr);
  }

  if (!isStylesPage && families.size) {
    WebFont.load({
      google: { families: Array.from(families) },
      classes: false,
      events: false,
    });
  }

  // Loading Custom Fonts
  const folder = process.env.REACT_APP_EXPORT_S3_FOLDER;
  const elementId = isStylesPage ? 'explo-custom-fonts-custom-styles' : 'explo-custom-fonts';
  if (!folder || orphanFonts.length + fontFamilies.length < 1) return;

  let fontFacesElement = document.getElementById(elementId);
  if (fontFacesElement === null) {
    fontFacesElement = document.createElement('style');
    fontFacesElement.setAttribute('id', elementId);
  } else {
    fontFacesElement.innerHTML = '';
  }

  // Load fonts without a family
  orphanFonts.forEach(({ font }) => {
    if (!font) return;
    const fontFace = createFontFaceCSS(font, folder, teamId);
    fontFacesElement?.appendChild(document.createTextNode(fontFace));
  });

  // Load fonts with a family
  // Create a map from fontFamilies to the src in fontFaces
  const fontFamilyMap = createFontWeightMap(fontFamilies);
  fontConfig.forEach(({ id, fontFaces }) => {
    // Unlike Google Fonts, the user may not have created a bold font variant
    // Features like bold column headers won't do anything
    const weights = fontFamilyMap.get(id);
    if (!weights) return; // Don't load this font family since it isn't used in global styles

    fontFaces.forEach(({ fontWeight, src }) => {
      // It's fine if either global styles or font family didn't define a font weight
      // But if both are specified, only load font weights used in global styles to prevent unnecessary requests
      if (fontWeight && weights.size && !weights.has(fontWeight)) return;
      const fontFace = createFontFaceCSS(src, folder, teamId, fontWeight, id);
      fontFacesElement?.appendChild(document.createTextNode(fontFace));
    });
  });

  document.head.appendChild(fontFacesElement);
}

export function getCalculatedStyles(globalStyleConfig: GlobalStyleConfig, theme: Theme) {
  const {
    base: {
      actionColor: {
        default: defaultActionColor,
        buttonColor: maybeButtonColor,
        interactionStateColor: maybeInteractionStateColor,
      },
      backgroundColor: baseBackgroundColor,
    },
    container: {
      fill: containerFillColor,
      outline: {
        enabled: containerOutlineEnabled,
        color: containerOutlineColor,
        weight: containerOutlineWeight,
      },
      shadow: {
        enabled: containerShadowEnabled,
        color: containerShadowColor,
        size: containerShadowSize,
      },
      cornerRadius: {
        default: defaultContainerCornerRadius,
        inputFields: maybeInputFieldCornerRadius,
      },
    },
    text: { secondaryColor: secondaryTextColor },
  } = globalStyleConfig;

  const backgroundColorObject = new Color(baseBackgroundColor);
  const { white, black } = theme.palette.ds;
  const buttonColor = maybeButtonColor ?? defaultActionColor;
  const interactionStateColor = new Color(maybeInteractionStateColor ?? defaultActionColor)
    // We want interaction states to have 10% opacity
    .fade(
      backgroundColorObject.isDark() ? INTERACTION_STATE_OPACITY : 1 - INTERACTION_STATE_OPACITY,
    )
    .rgb()
    .string();
  const actionColorOutline = new Color(defaultActionColor)
    .fade(1 - ACTION_COLOR_OUTLINE_OPACITY)
    .rgb();
  const containerFillColorObject = new Color(containerFillColor);
  const dropShadowColor = new Color(containerShadowColor)
    .fade(1 - DROP_SHADOW_OPACITY)
    .rgb()
    .string();
  const placeholderTextColor = new Color(secondaryTextColor)
    .fade(1 - PLACEHOLDER_TEXT_OPACITY)
    .rgb()
    .string();
  const inputFieldCornerRadius = maybeInputFieldCornerRadius ?? defaultContainerCornerRadius;
  const outlineBoxShadowBase = `0 0 0 ${containerOutlineWeight}px ${containerOutlineColor}`;

  const getButtonStyles = (backgroundColor: string, alpha: number) => {
    const mixedColor = mixColors(backgroundColor, white, alpha).rgb().string();
    return {
      backgroundColor: `${mixedColor} !important`,
      color: getTextColorForBackground(mixedColor, theme),
    };
  };

  const getMinimalButtonStyles = (backgroundColor: string, alpha: number) => {
    const mixedColor = mixColors(backgroundColor, white, alpha).rgb().string();
    return {
      backgroundColor: mixedColor,
      color: secondaryTextColor,
    };
  };

  const getTextSize = (type: keyof GlobalStyleTextConfig['overrides']) => {
    return (
      globalStyleConfig.text.overrides[type]?.size ||
      globalStyleConfig.text.textSize + TEXT_SIZE_OFFSET_MAP[type]
    );
  };

  const getTextFont = (type: keyof GlobalStyleTextConfig['overrides'], secondary?: boolean) => {
    const font =
      globalStyleConfig.text.overrides[type]?.font ||
      globalStyleConfig.text[secondary ? 'secondaryFont' : 'primaryFont'];
    return font ? getFontFamilyName(font) : undefined;
  };

  const getTextWeight = (type: keyof GlobalStyleTextConfig['overrides'], secondary?: boolean) =>
    globalStyleConfig.text.overrides[type]?.weight ||
    globalStyleConfig.text[secondary ? 'secondaryWeight' : 'primaryWeight'];

  const getTextColor = (type: keyof GlobalStyleTextConfig['overrides'], secondary?: boolean) =>
    globalStyleConfig.text.overrides[type]?.color ||
    globalStyleConfig.text[secondary ? 'secondaryColor' : 'primaryColor'];

  const getTextStyles = (
    type: keyof GlobalStyleTextConfig['overrides'],
    options: { color?: boolean; secondary?: boolean } = {},
  ) => {
    const { color = true, secondary } = options;
    const fontFamily = getTextFont(type, secondary);
    const fontWeight = getTextWeight(type, secondary);

    return {
      fontSize: getTextSize(type),
      ...(fontWeight && { fontWeight }),
      ...(fontFamily && { fontFamily }),
      ...(color && { color: getTextColor(type, secondary) }),
    };
  };

  return {
    base: {
      backgroundColorStyle: {
        backgroundColorStyle: {
          backgroundColor: baseBackgroundColor,
        },
      },
      actionColor: {
        default: {
          backgroundColorStyle: {
            backgroundColor: defaultActionColor,
          },
          blackOrWhiteColorStyle: {
            // TODO PD-1260 we should get rid of the important
            color: `${getTextColorForBackground(defaultActionColor, theme)} !important`,
          },
          boxShadowStyle: {
            // rgb(16 22 26 / 20%) part comes directly from Blueprint
            boxShadow: `0 0 0 1px ${defaultActionColor}, 0 0 0 3px ${actionColorOutline}, inset 0 1px 1px rgb(16 22 26 / 20%)`,
          },
          boxShadowImportantStyle: {
            boxShadow: `0 0 0 1px ${defaultActionColor}, 0 0 0 3px ${actionColorOutline}, inset 0 1px 1px rgb(16 22 26 / 20%) !important`,
          },
          borderColorStyle: {
            borderColor: defaultActionColor,
          },
        },
        buttonColor: {
          colorStyle: {
            color: buttonColor,
          },
          backgroundColorStyle: {
            backgroundColor: buttonColor,
          },
          buttonMinimalColor: {
            backgroundColor: 'transparent',
            color: secondaryTextColor,
          },
          buttonMinimalHoverStyle: getMinimalButtonStyles(buttonColor, 0.92),
          buttonMinimalActiveStyle: getMinimalButtonStyles(buttonColor, 0.84),
          buttonMinimalActiveHoverStyle: getMinimalButtonStyles(buttonColor, 0.76),
          blackOrWhiteColorStyle: {
            color: getTextColorForBackground(buttonColor, theme),
          },
          buttonHoverStyle: getButtonStyles(buttonColor, 0.92),
          buttonActiveStyle: getButtonStyles(buttonColor, 0.84),
          buttonActiveHoverStyle: getButtonStyles(buttonColor, 0.76),
        },
        interactionStateColor: {
          borderColorStyle: {
            borderColor: interactionStateColor,
          },
          boxShadowStyle: {
            boxShadow: `inset 0 0 0 1px ${interactionStateColor}`,
          },
        },
      },
    },
    container: {
      fill: {
        offsetBackgroundColorStyle: {
          backgroundColor: mixColors(
            containerFillColor,
            containerFillColorObject.isDark() ? white : black,
            1 - BACKGROUND_COLOR_OFFSET,
          )
            .rgb()
            .toString(),
        },
        deeperOffsetBackgroundColorStyle: {
          backgroundColor: mixColors(
            containerFillColor,
            containerFillColorObject.isDark() ? white : black,
            1 - BACKGROUND_COLOR_DEEPER_OFFSET,
          )
            .rgb()
            .toString(),
        },
      },
      outline: {
        borderStyle: {
          border: containerOutlineEnabled
            ? `${containerOutlineWeight}px solid ${containerOutlineColor}`
            : '1px solid transparent',
        },
        boxShadowStyle: {
          boxShadow: containerOutlineEnabled ? outlineBoxShadowBase : 'none',
        },
        boxShadowInsetStyle: {
          boxShadow: containerOutlineEnabled ? `inset ${outlineBoxShadowBase}` : 'none',
        },
      },
      shadow: {
        shadowStyle: {
          boxShadow:
            containerShadowEnabled && containerShadowSize
              ? `${containerShadowSize}px ${containerShadowSize}px ${
                  containerShadowSize * 2
                }px ${dropShadowColor}`
              : 'none',
        },
      },
      cornerRadius: {
        default: {
          borderRadiusStyle: {
            borderRadius: defaultContainerCornerRadius,
          },
        },
        inputFieldCornerRadius: {
          borderRadiusStyle: {
            borderRadius: inputFieldCornerRadius,
          },
        },
      },
    },
    text: {
      secondaryColor: {
        inputPlaceholderStyle: {
          color: placeholderTextColor,
        },
      },
      overrides: {
        body: {
          primaryStyle: {
            ...getTextStyles('body'),
          },
          secondaryColorStyle: {
            color: getTextColor('body', true),
          },
        },
      },
    },
  };
}

export const getWhiteOrBlack = (color: string | Color): string => {
  if (typeof color === 'string') color = new Color(color);
  return color.isDark() ? 'white' : 'black';
};
